import { sanitizeURL } from '../utils/sanitizeURL';

export const urlOpener = (url: string): void => {
  const urlToOpen = sanitizeURL(url);

  if (urlToOpen) {
    window.open(urlToOpen, '_self');
  }
};

export enum AppName {
  RacPad = 'RAC PAD',
  RacPadNoSpace = 'RACPAD'
}

const localhostDomain = 'http://localhost:3000';
const ANALYTICS_DEV_ID = 'UA-75793694-12';
const ANALYTICS_PROD_ID = 'UA-75793694-13';

export const localhost = {
  REACT_APP_API_URL: 'https://local-racpad.rentacenter.com/api',
  auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_FPWut7aB',
    userPoolWebClientId: '608s8dso4aecka8omjpukssq3n',
    oauth: {
      domain: 'auth-local-racpad.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile'],
      redirectSignIn: localhostDomain,
      redirectSignOut: localhostDomain,
      responseType: 'token',
      urlOpener
    }
  },
  vantiv: {
    PAY_PAGE_ID: 'uwrjGM3KiuKcqyAs'
  },
  microfrontends: {
    manualPO: {
      name: 'ManualPO',
      url: 'http://localhost:3001/manual-po'
    },
    configurations: {
      name: 'Configurations',
      url: 'http://localhost:3002/configs'
    },
    payment: {
      name: 'Payment',
      url: 'http://localhost:3003/payment'
    },
    dailyActivityPlanner: {
      name: 'DailyActivityPlanner',
      url: 'http://localhost:3006/dap'
    },
    reports: {
      name: 'Reports',
      url: 'http://localhost:3005/reports'
    },
    store: {
      name: 'Store',
      url: 'http://localhost:3007/store'
    },
    cashmgmt: {
      name: 'cashmgmt',
      url: 'http://localhost:3007/cashmgmt'
    }
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {
      debug: true,
      gaOptions: {
        cookieDomain: 'none'
      }
    }
  }
};

export const localhostIE11 = {
  ...localhost
};

export const local = {
  REACT_APP_API_URL: 'https://local-racpad.rentacenter.com/api',
  auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_FPWut7aB',
    userPoolWebClientId: '608s8dso4aecka8omjpukssq3n',
    oauth: {
      domain: 'auth-local-racpad.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile'],
      redirectSignIn: 'https://local-racpad.rentacenter.com',
      redirectSignOut: 'https://local-racpad.rentacenter.com',
      responseType: 'token',
      urlOpener
    }
  },
  vantiv: {
    PAY_PAGE_ID: 'uwrjGM3KiuKcqyAs'
  },
  microfrontends: {
    manualPO: {
      name: 'ManualPO',
      url: 'https://local-manual-po-racpad.rentacenter.com/manual-po'
    },
    configurations: {
      name: 'Configurations',
      url: 'https://local-configs-racpad.rentacenter.com/configs'
    },
    payment: {
      name: 'Payment',
      url: 'https://local-altam-racpad.rentacenter.com/payment'
    },
    dailyActivityPlanner: {
      name: 'DailyActivityPlanner',
      url: 'https://local-dap-racpad.rentacenter.com/dap'
    },
    reports: {
      name: 'Reports',
      url: 'https://local-reports-racpad.rentacenter.com/reports'
    },
    store: {
      name: 'Store',
      url: 'https://local-store-racpad.rentacenter.com/store'
    },
    cashmgmt: {
      name: 'cashmgmt',
      url: 'https://local-cashmgmt-racpad.rentacenter.com/cashmgmt'
    }
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const dev = {
  REACT_APP_API_URL: 'https://dev-racpad.rentacenter.com/api',
  auth: {
    region: 'us-east-1',
    // userPoolId: 'us-east-1_IX7X5lZqL',
    // userPoolWebClientId: '4o7nqktg06c45g59dqhd51gik5',
    userPoolId: 'us-east-1_8MbOyEYG6',
    userPoolWebClientId: '47r2ihk9fehcpn9t64thdbu2tl',
    oauth: {
      // domain: 'auth-dev-racpad.auth.us-east-1.amazoncognito.com',
      domain: 'auth-qa-racpad.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile'],
      redirectSignIn: 'https://dev-racpad.rentacenter.com',
      redirectSignOut: 'https://dev-racpad.rentacenter.com',
      responseType: 'token',
      urlOpener
    }
  },
  vantiv: {
    PAY_PAGE_ID: 'uwrjGM3KiuKcqyAs'
  },
  microfrontends: {
    manualPO: {
      name: 'ManualPO',
      url: 'https://dev-manual-po-racpad.rentacenter.com/manual-po'
    },
    configurations: {
      name: 'Configurations',
      url: 'https://dev-configs-racpad.rentacenter.com/configs'
    },
    payment: {
      name: 'Payment',
      url: 'https://dev-altam-racpad.rentacenter.com/payment'
    },
    dailyActivityPlanner: {
      name: 'DailyActivityPlanner',
      url: 'https://dev-dap-racpad.rentacenter.com/dap'
    },
    reports: {
      name: 'Reports',
      url: 'https://dev-reports-racpad.rentacenter.com/reports'
    },
    store: {
      name: 'Store',
      url: 'https://dev-store-racpad.rentacenter.com/store'
    },
    cashmgmt: {
      name: 'cashmgmt',
      url: 'https://dev-cashmgmt-racpad.rentacenter.com/cashmgmt'
    }
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const qa = {
  REACT_APP_API_URL: 'https://qa-racpad.rentacenter.com/api',
  auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_8MbOyEYG6',
    userPoolWebClientId: '47r2ihk9fehcpn9t64thdbu2tl',
    oauth: {
      domain: 'auth-qa-racpad.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile'],
      redirectSignIn: 'https://qa-racpad.rentacenter.com',
      redirectSignOut: 'https://qa-racpad.rentacenter.com',
      responseType: 'token',
      urlOpener
    }
  },
  vantiv: {
    PAY_PAGE_ID: 'uwrjGM3KiuKcqyAs'
  },
  microfrontends: {
    manualPO: {
      name: 'ManualPO',
      url: 'https://qa-manual-po-racpad.rentacenter.com/manual-po'
    },
    configurations: {
      name: 'Configurations',
      url: 'https://qa-configs-racpad.rentacenter.com/configs'
    },
    payment: {
      name: 'Payment',
      url: 'https://qa-altam-racpad.rentacenter.com/payment'
    },
    dailyActivityPlanner: {
      name: 'DailyActivityPlanner',
      url: 'https://qa-dap-racpad.rentacenter.com/dap'
    },
    reports: {
      name: 'Reports',
      url: 'https://qa-reports-racpad.rentacenter.com/reports'
    },
    store: {
      name: 'Store',
      url: 'https://qa-store-racpad.rentacenter.com/store'
    },
    cashmgmt: {
      name: 'cashmgmt',
      url: 'https://qa-cashmgmt-racpad.rentacenter.com/cashmgmt'
    }
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const uat = {
  REACT_APP_API_URL: 'http://uat-racpad.rentacenter.com/api',
  auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_FBmCthpda',
    userPoolWebClientId: '1d8vgr67fr7gli9abpv9j1cb3n',
    oauth: {
      domain: 'auth-uat-racpad.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile'],
      redirectSignIn: 'https://uat-racpad.rentacenter.com',
      redirectSignOut: 'https://uat-racpad.rentacenter.com',
      responseType: 'token',
      urlOpener
    }
  },
  vantiv: {
    PAY_PAGE_ID: 'uwrjGM3KiuKcqyAs'
  },
  microfrontends: {
    manualPO: {
      name: 'ManualPO',
      url: 'https://uat-manual-po-racpad.rentacenter.com/manual-po'
    },
    configurations: {
      name: 'Configurations',
      url: 'https://uat-configs-racpad.rentacenter.com/configs'
    },
    payment: {
      name: 'Payment',
      url: 'https://uat-altam-racpad.rentacenter.com/payment'
    },
    dailyActivityPlanner: {
      name: 'DailyActivityPlanner',
      url: 'https://uat-dap-racpad.rentacenter.com/dap'
    },
    reports: {
      name: 'Reports',
      url: 'https://uat-reports-racpad.rentacenter.com/reports'
    },
    store: {
      name: 'Store',
      url: 'https://uat-store-racpad.rentacenter.com/store'
    },
    cashmgmt: {
      name: 'cashmgmt',
      url: 'https://uat-cashmgmt-racpad.rentacenter.com/cashmgmt'
    }
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const artifact = {
  REACT_APP_API_URL: '_apiUrls.micro',
  auth: {
    region: '_auth.region',
    userPoolId: '_auth.userPoolId',
    userPoolWebClientId: '_auth.userPoolWebClientId',
    oauth: {
      domain: '_auth.oauth.domain',
      scope: ['phone', 'email', 'profile'],
      redirectSignIn: '_auth.oauth.redirectSignIn',
      redirectSignOut: '_auth.oauth.redirectSignOut',
      responseType: '_auth.oauth.responseType',
      urlOpener
    }
  },
  vantiv: {
    PAY_PAGE_ID: '_vantiv.payPageId'
  },
  microfrontends: {
    manualPO: {
      name: 'ManualPO',
      url: '_microUrls.manualPO'
    },
    configurations: {
      name: 'Configurations',
      url: '_microUrls.configuration'
    },
    payment: {
      name: 'Payment',
      url: '_microUrls.payment'
    },
    dailyActivityPlanner: {
      name: 'DailyActivityPlanner',
      url: '_microUrls.dap'
    },
    reports: {
      name: 'Reports',
      url: '_microUrls.reports'
    },
    store: {
      name: 'Store',
      url: '_microUrls.store'
    },
    cashmgmt: {
      name: 'cashmgmt',
      url: '_microUrls.cashmgmt'
    }
  },
  googleAnalytics: {
    trackingId: '_googleAnalytics.trackingId',
    options: {}
  }
};

export const prod = {
  REACT_APP_API_URL: 'http://racpad.rentacenter.com/api',
  auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_kEvxQJYvy',
    userPoolWebClientId: '4uqb6ra86hida60od45kjvm0g8',
    oauth: {
      domain: 'auth-racpad.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile'],
      redirectSignIn: 'https://racpad.rentacenter.com',
      redirectSignOut: 'https://racpad.rentacenter.com',
      responseType: 'token',
      urlOpener
    }
  },
  vantiv: {
    PAY_PAGE_ID: 'Fjm8Xzn5DhtCg9Sy'
  },
  microfrontends: {
    manualPO: {
      name: 'ManualPO',
      url: 'https://manual-po-racpad.rentacenter.com/manual-po'
    },
    configurations: {
      name: 'Configurations',
      url: 'https://configs-racpad.rentacenter.com/configs'
    },
    payment: {
      name: 'Payment',
      url: 'https://altam-racpad.rentacenter.com/payment'
    },
    dailyActivityPlanner: {
      name: 'DailyActivityPlanner',
      url: 'https://dap-racpad.rentacenter.com/dap'
    },
    reports: {
      name: 'Reports',
      url: 'https://reports-racpad.rentacenter.com/reports'
    },
    store: {
      name: 'Store',
      url: 'https://store-racpad.rentacenter.com/store'
    },
    cashmgmt: {
      name: 'cashmgmt',
      url: 'https://cashmgmt-racpad.rentacenter.com/cashmgmt'
    }
  },
  googleAnalytics: {
    trackingId: ANALYTICS_PROD_ID,
    options: {}
  }
};

let appConfig;

switch (process.env.REACT_APP_STAGE) {
  case 'localhost':
    appConfig = localhost;
    break;
  case 'localhost-ie11':
    appConfig = localhostIE11;
    break;
  case 'local':
    appConfig = local;
    break;
  case 'dev':
    appConfig = dev;
    break;
  case 'qa':
    appConfig = qa;
    break;
  case 'uat':
    appConfig = uat;
    break;
  case 'prod':
    appConfig = prod;
    break;
  case 'artifact':
    appConfig = artifact;
    break;
  default:
    appConfig = localhost;
    break;
}

export default {
  // Add common config values here
  ...appConfig
};
